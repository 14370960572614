import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesSmallWebsiteProductCard } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesSmallWebsiteProductCard,
    {
      bagOfProducts: boolean;
    }
  > =>
    createStyles({
      storeCardWrapper: {
        [theme.breakpoints.up('md')]: {
          height: 'auto',
        },
      },
      mainWrapper: () => ({
        width: 'auto',
        padding: 0,
      }),
      cardRoot: {
        position: 'relative',
        width: 'inherit',
        height: 'inherit',
        display: 'block',
        alignItems: 'center',
        overflow: 'visible',
        boxShadow: 'none',
        borderRadius: '6px',
        minHeight: '370px',
        padding: '18px 10px 30px',
        [theme.breakpoints.up('md')]: {
          minHeight: '222px',
          borderRadius: '8px',
          width: '650px',
          padding: '28px 30px 30px',
        },
      },
      productImage: {
        width: 'clamp(240px, 60vw, 400px) !important',
        marginBottom: '12px',
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          width: '280px !important',
          margin: '0px 30px 0px 0px',
          alignSelf: 'center',
        },
      },
      productDetailsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '330px',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'initial',
          alignItems: 'initial',
          minHeight: 'initial',
        },
      },
      productName: {
        fontSize: '18px',
        lineHeight: '22px',
        [theme.breakpoints.up('md')]: {
          fontSize: '20px',
          lineHeight: '24px',
        },
      },
      productType: {
        marginBottom: '8px',
        [theme.breakpoints.up('md')]: {
          marginBottom: '12px',
        },
      },
      productDetailsDescriptionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          alignItems: 'initial',
        },
      },
      basketIcon: {
        width: '20px',
        height: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: theme.palette.grey[50],
        fontSize: '12px',
        display: 'flex',
        marginBottom: '8px',
        [theme.breakpoints.up('md')]: {
          marginBottom: '12px',
        },
      },
      countryIcon: {
        width: '20px',
        height: '20px',
        // this is trick to remove blur of svg on IOS
        borderRadius: '50%',
        overflow: 'hidden',
        zIndex: 1,
      },
      productDetailsContainer: {
        padding: '0px 8px',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          padding: 0,
        },
      },
      productDetailsButton: {
        minWidth: 'auto',
        margin: '0 auto',
      },
    }),
);
