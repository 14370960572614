import { Box, CardActions } from '@material-ui/core';
import { useMobile, useProduct } from 'hooks';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getLang } from 'store/modules/config/selectors';
import ActionsOnCartButton from 'ui/common/ActionsOnCartButton';
import SellingUnitsSelectionButtons from 'ui/common/SellingUnitsSelectionButtons';

import { IProductActionButtons } from './types';
import useStyles from './styles';

const ProductActionButtons: FC<IProductActionButtons> = ({
  storeProductDisplay,
  onProductChange,
}) => {
  const { isMobile } = useMobile();

  const lang = useSelector(getLang);

  const { handleChooseUnit, handleAddStoreProduct, handleChangeStoreProductQuantity } =
    useProduct();

  const classes = useStyles({
    isMobile,
    lang,
  });

  return (
    <>
      <CardActions classes={{ root: classes.cardActions }}>
        <Box className={classes.cardActionsRootContent}>
          <Box className={classes.unitButtons}>
            <SellingUnitsSelectionButtons
              size="medium"
              height={isMobile ? '26px' : '30px'}
              activeSellingUnit={storeProductDisplay.activeSellingUnit}
              productSellingUnits={storeProductDisplay.productSellingUnits}
              onClick={(selectedSellingUnitId) => {
                handleChooseUnit(storeProductDisplay.id, selectedSellingUnitId);
                if (onProductChange) {
                  onProductChange();
                }
              }}
            />
          </Box>
          <Box className={classes.addToCartButton}>
            <ActionsOnCartButton
              size={isMobile ? 'large' : 'regular'}
              onChangeStoreProductQuantity={(action) => {
                handleChangeStoreProductQuantity(storeProductDisplay.id, action);
                if (onProductChange) {
                  onProductChange();
                }
              }}
              count={
                storeProductDisplay.cartData?.orderItem.isRemoved
                  ? 0
                  : storeProductDisplay.cartData?.orderItem.requestedQuantity
              }
              onAddStoreProduct={() => {
                // TODO when server will accept new source event - change it
                handleAddStoreProduct(
                  storeProductDisplay.id,
                  `mainPage${isMobile ? 'Mobile' : 'Desktop'}`,
                );
                if (onProductChange) {
                  onProductChange();
                }
              }}
            />
          </Box>
        </Box>
      </CardActions>
    </>
  );
};

export default ProductActionButtons;
