import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TWithMobileStyles } from 'types';
import { TStylesISmallWebsitePageWrapper } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesISmallWebsitePageWrapper, TWithMobileStyles> =>
    createStyles({
      categoriesRoot: ({ isMobile }) => {
        let top;
        if (isMobile) {
          top = 56;
        } else {
          top = 80;
        }

        return {
          position: 'sticky',
          top,
          background: theme.palette.background.paper,
          zIndex: 4,
          boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.06)',
        };
      },
      childrenWrp: {
        flex: 1,
        backgroundColor: theme.palette.background.default,
        zIndex: 3,
        minHeight: 500,
      },
    }),
);
