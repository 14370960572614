import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TProductDetailsStyles } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TProductDetailsStyles,
    {
      isMobile: boolean;
      lang: string;
    }
  > =>
    createStyles({
      priceMainWrapper: ({ isMobile }) => ({
        display: isMobile ? 'block' : 'flex',
        alignItems: 'baseline',
        marginBottom: isMobile ? 0 : theme.spacing(1),
      }),
      priceWrapper: ({ isMobile }) => ({
        display: 'flex',
        alignItems: 'center',
        marginBottom: isMobile ? theme.spacing(5 / 8) : 0,
        justifyContent: isMobile ? 'center' : 'initial',
      }),
      pricePerUnit: ({ isMobile, lang }) => ({
        fontSize: isMobile ? '18px' : '20px',
        lineHeight: isMobile ? '16px' : '20px',
        color: theme.palette.text.primary,
        fontWeight: lang === 'ru' ? 700 : 600,
      }),
      sellingUnit: ({ isMobile }) => ({
        fontSize: isMobile ? '14px' : '18px',
        lineHeight: isMobile ? '16px' : '20px',
        color: theme.palette.text.primary,
        fontWeight: 500,
      }),
      pricePerGram: {
        fontSize: '14px',
        lineHeight: '14px',
        color: '#7A7A7A',
        fontWeight: 'normal',
      },
      productExtraDetails: {
        fontSize: '14px',
        lineHeight: '20px',
        color: theme.palette.text.secondary,
        marginTop: '8px',
        textAlign: 'center',
        '& .productQualityInBag': {
          color: theme.palette.grey[600],
        },
        [theme.breakpoints.up('md')]: {
          textAlign: 'initial',
          marginTop: '14px',
        },
      },

      ratingWrapper: {
        marginTop: '12px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          marginTop: '8px',
          flexDirection: 'initial',
        },
      },
      descriptionText: {
        color: theme.palette.text.secondary,
        fontSize: '12px',
        lineHeight: '14px',
        marginLeft: theme.spacing(6 / 8),
        marginTop: '4px',
        [theme.breakpoints.up('md')]: {
          marginTop: '0',
          textAlign: 'inherit',
          color: theme.palette.text.secondary,
          marginLeft: theme.spacing(6 / 8),
        },
      },
    }),
);
