import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesProductActionButtons } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesProductActionButtons> =>
    createStyles({
      cardActions: {
        width: 'fit-content',
        bottom: 0,
        padding: 0,
        marginTop: '14px',
        [theme.breakpoints.up('md')]: {
          marginTop: '16px',
          position: 'relative',
        },
      },
      cardActionsRootContent: {
        [theme.breakpoints.up('md')]: {
          margin: '0',
          justifyContent: 'flex-start',
          display: 'flex',
        },
      },
      unitButtons: {
        display: 'flex',
        marginBottom: '8px',
        [theme.breakpoints.up('md')]: {
          marginBottom: '4px',
        },
      },
      addToCartButton: {
        [theme.breakpoints.up('md')]: {
          marginLeft: theme.spacing(15 / 8),
        },
      },
    }),
);
