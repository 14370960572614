import { Box, Button, CardMedia } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import classNames from 'classnames';
import ProductActionButtons from 'components/common/SmallWebsiteContent/components/ProductActionButtons';
import ProductDetails from 'components/common/SmallWebsiteContent/components/ProductDetails';
import ProductPrice from 'components/common/SmallWebsiteContent/components/ProductPrice';
import { useMobile } from 'hooks';
import { useMultiLanguage } from 'hooks/useMultiLanguage';
import { useProduct } from 'hooks/useProduct';
import React, { FC, useMemo } from 'react';
import { CircleFlag } from 'react-circle-flags';

import Container from 'ui/common/Container';
import Icon from 'ui/common/icons/Icon';
import ProductQualityTag from 'ui/common/ProductQualityTag';
import Typography from 'ui/common/Typography';

import useStyles from './styles';
import { ISmallWebsiteProductCard } from './types';

const SmallWebsiteProductCard: FC<ISmallWebsiteProductCard> = ({
  storeProductDisplay,
  onProductChange,
}) => {
  const { isMobile } = useMobile();

  const { renderLanguageField } = useMultiLanguage();

  const { handleProductDetailsPopup } = useProduct();

  const classes = useStyles({
    bagOfProducts: storeProductDisplay.bagOfProducts,
  });

  const productCardDetails = useMemo(() => {
    return (
      <>
        <Box className={classes.productDetailsDescriptionWrapper}>
          <Typography className={classes.productName}>{storeProductDisplay.displayName}</Typography>
          {isMobile && <ProductDetails storeProductDisplay={storeProductDisplay} />}
          {!isMobile && (
            <>
              <ProductPrice storeProductDisplay={storeProductDisplay} />
              <Box>
                <ProductActionButtons
                  storeProductDisplay={storeProductDisplay}
                  onProductChange={onProductChange}
                />
              </Box>
              <ProductDetails storeProductDisplay={storeProductDisplay} />
            </>
          )}
        </Box>
        {isMobile && <ProductPrice storeProductDisplay={storeProductDisplay} />}
      </>
    );
  }, [
    classes.productDetailsDescriptionWrapper,
    classes.productName,
    isMobile,
    onProductChange,
    storeProductDisplay,
  ]);

  return (
    <Container className={classes.mainWrapper}>
      <Box className={classes.storeCardWrapper}>
        <Card classes={{ root: classes.cardRoot }}>
          <Box position="absolute" zIndex={1} top={10}>
            {storeProductDisplay.productQuality &&
              storeProductDisplay.productQuality.displayQuality && (
                <Box className={classes.productType}>
                  <ProductQualityTag
                    label={renderLanguageField(
                      storeProductDisplay.productQuality.multiLang,
                      'name',
                    )}
                  />
                </Box>
              )}
            {storeProductDisplay.bagOfProducts && (
              <Icon classes={{ root: classes.basketIcon }} color="secondary" name="icon-basket" />
            )}
            {storeProductDisplay.country && (
              <Box className={classes.countryIcon}>
                <CircleFlag
                  countryCode={storeProductDisplay.country.shortIdentifier}
                  height="20px"
                />
              </Box>
            )}
          </Box>
          <Box className={classes.productDetailsWrapper}>
            <Box className={classes.productDetailsContainer}>
              <Button
                onClick={() => handleProductDetailsPopup(storeProductDisplay.id)}
                className={classNames(classes.productDetailsButton, 'unstyled-button')}
                disableTouchRipple
              >
                <CardMedia
                  image={storeProductDisplay.imageUrl}
                  className={classes.productImage}
                  alt={storeProductDisplay.fullName}
                  component="img"
                />
                {isMobile && productCardDetails}
              </Button>
              {!isMobile && productCardDetails}
            </Box>
            {isMobile && (
              <Box>
                <ProductActionButtons
                  storeProductDisplay={storeProductDisplay}
                  onProductChange={onProductChange}
                />
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default SmallWebsiteProductCard;
