import { Box, useTheme } from '@material-ui/core';
import { format, parse } from 'date-fns';
import { useMobile, useRenderDate } from 'hooks';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getLang } from 'store/modules/config/selectors';
import Icon from 'ui/common/icons/Icon';
import DropDown from 'ui/common/inputs/DropDown';
import Typography from 'ui/common/Typography';
import { formatHourRange, getDDMM } from 'utils';
import { dateFormatInOrderHistory, defaultDateFormat } from 'utils/constants';

import useStyles from './styles';
import { ISelfPickupDropDownOption, ISelfPickupsDropDown } from './types';

const SelfPickupsDropDown: FC<ISelfPickupsDropDown> = ({
  selectedPickupArea,
  selfPickupAreas,
  handleSelfPickupClick,
  disabled,
  handleDropDownClick,
}) => {
  const { isMobile } = useMobile();

  const lang = useSelector(getLang);

  const theme = useTheme();

  const classes = useStyles({
    isMobile,
    lang,
  });
  const { renderDay } = useRenderDate();
  const { t } = useTranslation();

  const [selectedPickupId, setSelectedPickupId] = useState<string>('');

  const optionTemplate = useCallback(
    (
      selfPickupAreaName,
      date,
      dayOfWeek,
      deliveryDayAndTime,
      distanceInKmToSelectedAddress,
      storeServiceAreaId,
      isInputSelectedText,
    ) => {
      const isSelectedPoint = storeServiceAreaId === selectedPickupId;
      const formattedDate = date && getDDMM(date);

      return (
        <Box display="flex" alignItems="center" width="100%">
          <Icon
            name={isMobile ? 'icon-store' : 'icon-store-rounded'}
            classes={{ root: classes.storeIcon }}
            color={isSelectedPoint ? 'primary' : 'inherit'}
          />
          <Box width="100%">
            <Box
              display={isMobile ? 'initial' : 'flex'}
              width="100%"
              style={{
                color: theme.palette.text.secondary,
              }}
            >
              {isInputSelectedText && isMobile && (
                <Typography className={classes.areaName}>
                  {selfPickupAreaName} {dayOfWeek} ,&nbsp; {formattedDate && `${formattedDate},`}
                  {deliveryDayAndTime}
                </Typography>
              )}
              {(!isMobile || (!isInputSelectedText && isMobile)) && (
                <>
                  <Box
                    display="flex"
                    alignItems={isMobile ? 'initial' : 'center'}
                    maxWidth={isMobile ? '100%' : (isInputSelectedText && '60%') || '50%'}
                  >
                    <Typography className={classes.areaName}>{selfPickupAreaName}</Typography>
                  </Box>
                  {!isMobile && <Box className={classes.verticalDivider} />}
                  <Box display="flex" className={classes.dayAndTimeWrapper}>
                    <Typography className={classes.dayAndTime} fontWeight="medium">
                      {dayOfWeek}
                    </Typography>
                    {formattedDate && (
                      <Typography className={classes.dayAndTime} fontWeight="medium">
                        ,&nbsp;{formattedDate}
                      </Typography>
                    )}
                    <Typography
                      className={classes.dayAndTime}
                      fontWeight="medium"
                      isTranslate={false}
                    >
                      ,&nbsp;{deliveryDayAndTime}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
            {distanceInKmToSelectedAddress && (
              <Box display="flex" mt={isMobile ? 4 / 8 : 0} className={classes.distanceWrapper}>
                <Typography
                  variant="body1"
                  color="mediumGrey"
                  fontSize={13}
                  className={classes.distance}
                >
                  {distanceInKmToSelectedAddress?.toFixed(1)}
                  &nbsp;
                </Typography>
                <Typography
                  variant="body1"
                  color="mediumGrey"
                  fontSize={13}
                  className={classes.distance}
                >
                  {'dialog.delivery.kmFromYourCurrentLocation'}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      );
    },
    [
      classes.areaName,
      classes.dayAndTime,
      classes.dayAndTimeWrapper,
      classes.distance,
      classes.distanceWrapper,
      classes.storeIcon,
      classes.verticalDivider,
      isMobile,
      selectedPickupId,
      theme.palette.text.secondary,
    ],
  );

  const dropDownOptions = useMemo(() => {
    const options: ISelfPickupDropDownOption[] = [];

    selfPickupAreas.forEach((selfPickupArea) => {
      selfPickupArea?.availableArea?.forEach((availableArea) => {
        availableArea.availableHours.forEach((availableHour) => {
          const deliveryDayAndTime = formatHourRange(
            availableHour.openHour,
            availableHour.duration,
          );
          const uniqId = `${selfPickupArea.serviceArea.storeServiceAreaId} ${selfPickupArea.serviceArea.name} ${availableArea?.date} ${availableArea?.dayOfWeek.id} ${deliveryDayAndTime}`;

          options.push({
            text: optionTemplate(
              selfPickupArea.serviceArea.name,
              availableArea?.date,
              availableArea.dayOfWeek.resolvedName,
              deliveryDayAndTime,
              selfPickupArea.serviceArea.distanceInKmToSelectedAddress,
              uniqId,
              false,
            ),
            value: uniqId,
            id: uniqId,
            selectedText: optionTemplate(
              selfPickupArea.serviceArea.name,
              availableArea?.date,
              availableArea.dayOfWeek.resolvedName,
              deliveryDayAndTime,
              null,
              uniqId,
              true,
            ),
            customValue: {
              orderMode: 'new',
              orderType: 'selfPickup',
              selfPickupLocation: selfPickupArea.serviceArea,
              orderDate: availableArea?.date,
              orderHour: deliveryDayAndTime,
              preferredHour: availableHour.openHour,
              preferredDay: availableArea.dayOfWeek.id,
              timeFrame: {
                dayOfWeek: availableArea.dayOfWeek,
                date: availableArea?.date,
                openHour: availableHour.openHour,
                duration: availableHour?.duration,
              },
            },
          });
        });
      });
    });

    return options;
  }, [optionTemplate, selfPickupAreas]);

  const onDropDownValueChange = useCallback(
    (e) => {
      const dropDownValue = e.target.value as string;
      setSelectedPickupId(dropDownValue);
      const selectedArea = dropDownOptions.find((area) => area.id === dropDownValue);
      handleSelfPickupClick(selectedArea?.customValue as ISelfPickupDropDownOption['customValue']);
    },
    [dropDownOptions, handleSelfPickupClick],
  );

  useEffect(() => {
    if (selectedPickupArea?.selfPickupLocation?.storeServiceAreaId) {
      let dateToDisplay = selectedPickupArea?.orderDate;

      if (selectedPickupArea.orderMode === 'edit' && selectedPickupArea?.orderDate) {
        const parsedDate = parse(
          selectedPickupArea.orderDate,
          dateFormatInOrderHistory,
          new Date(),
        );
        dateToDisplay = format(parsedDate, defaultDateFormat);
      }

      const uniqId =
        selectedPickupArea &&
        `${selectedPickupArea?.selfPickupLocation?.storeServiceAreaId} ${selectedPickupArea?.selfPickupLocation?.name} ${dateToDisplay} ${selectedPickupArea?.preferredDay} ${selectedPickupArea.orderHour}`;
      if (!dropDownOptions.length) return;

      const isSelectedAreaExist = dropDownOptions.find((area) => area.id === uniqId);

      if (isSelectedAreaExist) {
        setSelectedPickupId(uniqId);
        return;
      }

      setSelectedPickupId('');
    }
    setSelectedPickupId('');
  }, [handleSelfPickupClick, dropDownOptions, renderDay, selectedPickupArea, t]);

  return (
    <>
      <DropDown
        disabled={disabled}
        fullWidth
        isShowLabel
        size="extraLarge"
        addBorderRadius={false}
        outlinedInputStyle={{
          background: theme.palette.background.paper,
          height: '50px',
          maxWidth: '650px',
          borderRadius: '6px',
          opacity: disabled ? 0.3 : 1,
        }}
        dropDownOptions={dropDownOptions}
        MenuProps={{
          classes: { paper: classes.paper, list: classes.menuList },
          BackdropProps: {
            style: { backgroundColor: 'rgba(255, 255, 255, 0.5)' },
          },
        }}
        placeholder={'smallEC.chooseFromTheList'}
        menuItemProps={{
          classes: { root: classes.menuItem, selected: classes.menuItemSelected },
        }}
        value={selectedPickupId}
        onChange={onDropDownValueChange}
        onClick={handleDropDownClick}
        selectOverrideClasses={{
          notchedOutline: classes.notchedOutline,
          placeholder: classes.placeholder,
        }}
      />
    </>
  );
};

export default SelfPickupsDropDown;
