import { PaymentMethodService } from 'api';
import { ICheckoutStep3 } from 'components/common/checkout/CheckoutStep3/components/types';
import SmallWebsitePayment from 'components/common/SmallWebsiteContent/components/checkout/SmallWebsitePayment';

import { useWebsiteDetails } from 'hooks';
import { useCheckout } from 'hooks/useCheckout';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import { authSelectors, orderSelectors, prepareToPlaceOrderSelectors } from 'store';

import { TCreditCard, TPaymentMethodType } from 'types';
import { ISmallWebsiteCheckout } from './types';

const SmallWebsiteCheckout: FC<ISmallWebsiteCheckout> = ({ isPaymentSectionActive }) => {
  const order = useSelector(orderSelectors.orderData);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const prepareToPlaceOrder = useSelector(prepareToPlaceOrderSelectors.getPrepareToPlaceOrder);

  const websiteDetails = useWebsiteDetails();

  const { preparePaymentsMethods, handleCheckoutSubmit } = useCheckout();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [activePaymentType, setActivePaymentType] = useState<TPaymentMethodType>('creditCard');
  const [paymentMethods, setPaymentMethods] = useState<TCreditCard[]>([]);
  const [deferredPaymentAllowed, setDeferredPaymentAllowed] = useState<boolean>(false);
  const [noPaymentMethodAllowed, setNoPaymentMethodAllowed] = useState<boolean>(false);
  const [maxOrderPaymentsAllowed, setMaxOrderPaymentsAllowed] = useState<number>(1);

  const handleChangePaymentMethod = useCallback<Required<ICheckoutStep3>['onChangePaymentMethod']>(
    (newType) => () => {
      setActivePaymentType(newType);
    },
    [],
  );

  const handleSubmit = useCallback(
    (arg) => {
      handleCheckoutSubmit(arg, activePaymentType, { setLoading: setButtonLoading }, false);
    },
    [activePaymentType, handleCheckoutSubmit],
  );

  const paymentsMethodsArray = useMemo(() => {
    return preparePaymentsMethods(deferredPaymentAllowed, noPaymentMethodAllowed);
  }, [deferredPaymentAllowed, noPaymentMethodAllowed, preparePaymentsMethods]);

  useEffect(() => {
    if (!isLoggedIn) {
      setPaymentMethods([]);
      setDeferredPaymentAllowed(false);
      setNoPaymentMethodAllowed(false);
      setMaxOrderPaymentsAllowed(1);
      return;
    }

    PaymentMethodService.getPaymentMethod({ storeId: websiteDetails.store.id }).then(
      (actionResult) => {
        setPaymentMethods(actionResult.data.paymentMethods);
        setDeferredPaymentAllowed(actionResult.data.deferredPaymentAllowed);
        setNoPaymentMethodAllowed(actionResult.data.noPaymentMethodAllowed);
        setMaxOrderPaymentsAllowed(actionResult.data.maxOrderPaymentsAllowed);
      },
    );
  }, [websiteDetails, isLoggedIn]);

  useEffect(() => {
    if (order?.paymentMethodType.name) {
      const index = paymentsMethodsArray.findIndex((paymentMethod) => {
        return paymentMethod.id === order?.paymentMethodType.name;
      });
      if (index !== -1) {
        setActivePaymentType(order?.paymentMethodType.name as TPaymentMethodType);
      }
    }
  }, [order?.paymentMethodType.name, paymentsMethodsArray]);

  return (
    <SmallWebsitePayment
      buttonLoading={buttonLoading}
      activePaymentType={activePaymentType}
      paymentsMethodsArray={paymentsMethodsArray}
      onChangePaymentMethod={handleChangePaymentMethod}
      hasExistingPaymentMethods={paymentMethods.length !== 0}
      paymentMethods={paymentMethods}
      onSubmit={handleSubmit}
      serviceAreaUsageFees={prepareToPlaceOrder.serviceAreaUsageFees}
      maxOrderPaymentsAllowed={maxOrderPaymentsAllowed}
      showPlaceOrderButton={isLoggedIn && !!isPaymentSectionActive}
    />
  );
};

export default SmallWebsiteCheckout;
