import { Box, Divider, Grid, useTheme } from '@material-ui/core';
import AgeValidationSection from 'components/common/checkout/CheckoutStep3/components/AgeValidationSection';

import { IPaymentFormValues, PaymentForm, TOnSubmitForm } from 'components/common/forms';
import { CheckBoxWithLabelField } from 'components/common/formsFields';
import SkeletonCheckoutStep3 from 'components/common/skeletons/SkeletonCheckoutStep3';
import SmallWebsiteFinalCostData from 'components/common/SmallWebsiteContent/components/checkout/SmallWebsiteFinalCostData';

import { ISmallWebsitePayment } from 'components/common/SmallWebsiteContent/components/checkout/SmallWebsitePayment/types';

import { Field } from 'formik';
import { useCart, useMobile } from 'hooks';
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { orderSelectors, prepareToPlaceOrderSelectors, storeProductSelectors } from 'store';
import { getOrderDetails, getOrderMode } from 'store/modules/orderDetails/selectors';
import { getOrderDiscounts } from 'store/modules/orderDiscounts/selectors';
import PaymentMethod from 'ui/common/buttons/PaymentMethod';
import PlaceOrderButton from 'ui/common/buttons/PlaceOrderButton';
import Icon, { IIcon } from 'ui/common/icons/Icon';
import NumberOfPaymentsDropdown from 'ui/common/NumberOfPaymentsDropdown';
import PaymentInfoSection from 'ui/common/PaymentInfoSection';
import { getCreditCardBrand } from 'ui/common/PaymentMethodInfoCard/helpers';
import Tooltip from 'ui/common/Tooltip';

import Typography from 'ui/common/Typography';

import CreditCardsDropDown, {
  ICreditCardsDropDown,
  TCreditCardOption,
} from 'ui/mobile/inputs/CreditCardsDropDown';
import { isOrderContainsWeighableItems } from 'utils/helpers/order/items';
import useStyles from './styles';

const SmallWebsitePayment: FC<ISmallWebsitePayment> = ({
  buttonLoading,
  paymentsMethodsArray,
  activePaymentType,
  onChangePaymentMethod,
  hasExistingPaymentMethods,
  paymentMethods,
  onSubmit,
  maxOrderPaymentsAllowed,
  loading,
  showPlaceOrderButton,
}) => {
  const { isMobile } = useMobile();
  const theme = useTheme();

  const prepareToPlaceOrder = useSelector(prepareToPlaceOrderSelectors.getPrepareToPlaceOrder);
  const orderDiscounts = useSelector(getOrderDiscounts);
  const orderMode = useSelector(getOrderMode);
  const orderDetails = useSelector(getOrderDetails);
  const orderItems = useSelector(orderSelectors.orderItems);
  const { storeProductById } = useSelector(storeProductSelectors.storeProductsData);

  const { t } = useTranslation();
  const { cartEstimation, limitedByAgeProducts } = useCart();

  const [creditCardDropdownValue, setCreditCardDropdownValue] = useState<string>('');
  const [numberOfPayments, setNumberOfPayments] = useState<number>(1);

  const classes = useStyles({ isMobile, paymentsMethodsArrayLength: paymentsMethodsArray.length });

  const numberOfPaymentsSection = useCallback(
    (borderRadius?: string) => {
      return (
        <NumberOfPaymentsDropdown
          outlinedInputStyle={{
            background: theme.palette.background.paper,
            borderRadius: borderRadius || '6px',
          }}
          maxNumberOfPayments={maxOrderPaymentsAllowed}
          value={numberOfPayments}
          onChange={(e) => setNumberOfPayments(e.target.value as number)}
        />
      );
    },
    [maxOrderPaymentsAllowed, numberOfPayments, theme.palette.background.paper],
  );

  const handleSubmit = useCallback(() => {
    onSubmit({
      creditCardValue: creditCardDropdownValue,
      numberOfPayments,
    });
  }, [onSubmit, creditCardDropdownValue, numberOfPayments]);

  const handlePaymentFormSubmit = useCallback<TOnSubmitForm<IPaymentFormValues>>(
    (values, formikHelpers) => {
      onSubmit({ numberOfPayments, values, formikHelpers });
    },
    [numberOfPayments, onSubmit],
  );

  const handleCreditCardChange = useCallback<Required<ICreditCardsDropDown>['onChange']>(
    (event) => {
      const { value } = event.target;

      setCreditCardDropdownValue(value as string);
    },
    [],
  );

  const checkBoxLabel = useMemo<ReactNode>(() => {
    return (
      <Box display="flex" alignItems="center">
        <Typography variant="body1" className={classes.saveCreditLabel}>
          {'forms.checkout.saveCredit'}
        </Typography>
        <Tooltip
          placement="left"
          title={t('forms.checkout.saveCreditTooltip') as string}
          initialClasses={{
            tooltip: classes.tooltip,
          }}
        >
          <Box ml={1} display="flex">
            <Icon name="icon-question-1" color="primary" fontSize="small" />
          </Box>
        </Tooltip>
      </Box>
    );
  }, [classes.saveCreditLabel, classes.tooltip, t]);

  const paymentInfoSection = useMemo<ReactNode>(() => {
    switch (activePaymentType) {
      case 'creditCard':
        return (
          <Box my={24 / 8}>
            <PaymentInfoSection name="icon-lock">
              {t('paymentInfoSection.creditCard.text')}
              &nbsp;
              <a
                className={classes.link}
                href="https://www.creditguard.co.il/products/%d7%a1%d7%9c%d7%99%d7%a7%d7%94-%d7%91%d7%90%d7%99%d7%a0%d7%98%d7%a8%d7%a0%d7%98"
                target="_blank"
                rel="noreferrer"
              >
                <Box color="primary.main" component="span" fontSize={14} fontWeight={600}>
                  {t('paymentInfoSection.creditCard.link')}
                </Box>
              </a>
            </PaymentInfoSection>
          </Box>
        );
      case 'deferredPayment':
        return (
          <Box my={24 / 8}>
            <PaymentInfoSection name="icon-pay">
              {'paymentInfoSection.deferredPayment'}
            </PaymentInfoSection>
          </Box>
        );
      case 'absent':
        return (
          <Box my={24 / 8}>
            <PaymentInfoSection name="icon-pay">{'paymentInfoSection.absent'}</PaymentInfoSection>
          </Box>
        );
      case 'cash':
        return (
          <Box my={24 / 8}>
            <PaymentInfoSection name="icon-pay">{'paymentInfoSection.cash'}</PaymentInfoSection>
          </Box>
        );
      case 'bit':
        return (
          <Box my={24 / 8}>
            <PaymentInfoSection name="icon-pay">
              {`paymentInfoSection.bit.${
                isOrderContainsWeighableItems(orderItems, storeProductById)
                  ? 'weighableItems'
                  : 'onlyNonWeighableItems'
              }`}
            </PaymentInfoSection>
          </Box>
        );
      default:
        return null;
    }
  }, [activePaymentType, classes.link, orderItems, storeProductById, t]);

  const finalCost = useMemo(() => {
    const promotionsToDisplay = orderDiscounts.filter(
      (discount) =>
        discount.discountType.name !== 'itemsPromotionDiscount' &&
        discount.discountType.name !== 'itemForFree',
    );

    return (
      showPlaceOrderButton &&
      (!!promotionsToDisplay.length || !!prepareToPlaceOrder.serviceAreaUsageFees) && (
        <Box>
          <Divider className={classes.dividerRoot} />
          <Box my={25 / 8} mx={20 / 8}>
            <SmallWebsiteFinalCostData />
          </Box>
        </Box>
      )
    );
  }, [
    classes.dividerRoot,
    orderDiscounts,
    prepareToPlaceOrder.serviceAreaUsageFees,
    showPlaceOrderButton,
  ]);

  const defaultFooter = useMemo<ReactNode>(() => {
    return (
      <>
        {paymentInfoSection}
        {finalCost}
        <Box width="100%">
          {showPlaceOrderButton && (
            <PlaceOrderButton
              orderMode={orderMode}
              activePaymentType={activePaymentType}
              fullWidth
              type="submit"
              onClick={handleSubmit}
              price={cartEstimation}
              disabled={!!limitedByAgeProducts.length || orderDetails.limitationByAgeChecked}
              loading={buttonLoading}
            />
          )}
          <Box mt={1} display="flex" justifyContent="center">
            <Typography color="mediumGrey" fontSize={13}>
              {'topCard.revaluation'}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }, [
    finalCost,
    showPlaceOrderButton,
    paymentInfoSection,
    orderMode,
    activePaymentType,
    handleSubmit,
    cartEstimation,
    limitedByAgeProducts.length,
    orderDetails.limitationByAgeChecked,
    buttonLoading,
  ]);

  const creditCardOption: TCreditCardOption[] = useMemo(() => {
    return paymentMethods.map(({ id, fourLastDigits, creditCard }) => {
      // check if credit card brand is not "private" (id 0)
      const creditCardBrandLabel = creditCard?.brand.id
        ? creditCard?.brand.resolvedName
        : creditCard?.representative.resolvedName;

      return {
        id,
        value: id,
        icon: getCreditCardBrand(creditCard, true) as IIcon['name'],
        text: [fourLastDigits || '', creditCardBrandLabel || ''],
      };
    });
  }, [paymentMethods]);

  const paymentForm = useCallback(
    (showNumberOfPaymentsDropdown: boolean) => {
      return (
        <PaymentForm
          handleSubmit={handlePaymentFormSubmit}
          price={cartEstimation}
          buttonLoading={buttonLoading}
          showPlaceOrderButton={showPlaceOrderButton}
          type="smallEC"
        >
          <Box
            mt={showNumberOfPaymentsDropdown && maxOrderPaymentsAllowed > 1 ? 24 / 8 : 0}
            mb={showNumberOfPaymentsDropdown && maxOrderPaymentsAllowed > 1 ? 24 / 8 : 0}
            display={showNumberOfPaymentsDropdown && maxOrderPaymentsAllowed > 1 ? 'flex' : 'block'}
          >
            <Grid container spacing={3} alignItems="flex-end">
              {showNumberOfPaymentsDropdown && maxOrderPaymentsAllowed > 1 && (
                <Grid item xs={12}>
                  {numberOfPaymentsSection()}
                </Grid>
              )}
              <Grid item xs={12}>
                <Field
                  name="is_saveCredit"
                  type="checkbox"
                  checkBoxSize="medium"
                  label={checkBoxLabel}
                  component={CheckBoxWithLabelField}
                />
              </Grid>
            </Grid>
          </Box>
          {paymentInfoSection}
          {finalCost}
        </PaymentForm>
      );
    },
    [
      handlePaymentFormSubmit,
      cartEstimation,
      buttonLoading,
      showPlaceOrderButton,
      maxOrderPaymentsAllowed,
      numberOfPaymentsSection,
      checkBoxLabel,
      paymentInfoSection,
      finalCost,
    ],
  );

  const paymentMethodsContent = useMemo(() => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid
            item
            lg={maxOrderPaymentsAllowed > 1 ? 6 : 12}
            md={maxOrderPaymentsAllowed > 1 ? 6 : 12}
            xs={12}
          >
            <CreditCardsDropDown
              value={creditCardDropdownValue}
              options={creditCardOption}
              onChange={handleCreditCardChange}
              outlinedInputStyle={{
                background: theme.palette.background.paper,
                borderRadius: isMobile ? '6px' : '20px',
              }}
            />
          </Grid>
          {maxOrderPaymentsAllowed > 1 && (
            <Grid item lg={6} md={6} xs={12}>
              {numberOfPaymentsSection(isMobile ? '6px' : '20px')}
            </Grid>
          )}
        </Grid>
        {creditCardDropdownValue === 'add_card' ? (
          <>
            <Typography
              color="textSecondary"
              fontWeight="medium"
              fontSize={16}
              className={classes.label}
            >
              {'forms.checkout.creditDetails'}
            </Typography>
            {paymentForm(false)}
          </>
        ) : (
          defaultFooter
        )}
      </>
    );
  }, [
    maxOrderPaymentsAllowed,
    creditCardDropdownValue,
    creditCardOption,
    handleCreditCardChange,
    theme.palette.background.paper,
    isMobile,
    numberOfPaymentsSection,
    classes.label,
    paymentForm,
    defaultFooter,
  ]);

  const cardContent = useMemo(() => {
    if (hasExistingPaymentMethods) {
      return paymentMethodsContent;
    }
    return paymentForm(true);
  }, [hasExistingPaymentMethods, paymentMethodsContent, paymentForm]);

  const mainContent = useMemo<ReactNode>(() => {
    switch (activePaymentType) {
      case 'creditCard':
        return cardContent;
      case 'deferredPayment':
      case 'cash':
      case 'absent':
      case 'bit':
        return defaultFooter;
      default:
        return null;
    }
  }, [activePaymentType, cardContent, defaultFooter]);

  const availablePaymentMethods = useMemo(() => {
    if (paymentsMethodsArray.length === 1) return;

    return (
      <Box className={classes.availablePaymentMethodsWrapper}>
        <Typography color="textSecondary" fontWeight="medium" fontSize={16}>
          {'checkout.choosePayment'}
        </Typography>
        <Box className={classes.methodsWrapper}>
          <Grid container className={classes.paymentGridWrapper}>
            {paymentsMethodsArray.map(({ text, iconName, id }) => {
              const activeMethod = activePaymentType === id;

              return (
                <Grid
                  key={id}
                  item
                  xs={6}
                  sm={6}
                  md={3}
                  lg={3}
                  className={classes.paymentGridItemWrapper}
                >
                  <Box className={classes.paymentMethodItem}>
                    <PaymentMethod
                      onClick={onChangePaymentMethod && onChangePaymentMethod(id)}
                      text={text}
                      icon={iconName}
                      isActive={activeMethod}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>

        <Divider className={classes.dividerRoot} />
      </Box>
    );
  }, [
    activePaymentType,
    classes.availablePaymentMethodsWrapper,
    classes.dividerRoot,
    classes.methodsWrapper,
    classes.paymentGridItemWrapper,
    classes.paymentGridWrapper,
    classes.paymentMethodItem,
    onChangePaymentMethod,
    paymentsMethodsArray,
  ]);

  useEffect(() => {
    if (paymentMethods.length !== 0) {
      setCreditCardDropdownValue(paymentMethods[0].id);
    }
  }, [paymentMethods]);

  return (
    <Box className={classes.root}>
      {!!limitedByAgeProducts.length && (
        <AgeValidationSection tooltipTitle={limitedByAgeProducts.join(',')} />
      )}
      <Typography fontWeight="bold" color="secondary" variant="body2" className={classes.formTitle}>
        {'stepper.order.payment'}
      </Typography>
      {availablePaymentMethods}

      <Typography
        color="textSecondary"
        fontWeight="medium"
        fontSize={16}
        className={classes.creditDetailsText}
      >
        {activePaymentType === 'creditCard' &&
          (hasExistingPaymentMethods
            ? 'forms.checkout.billingCredit'
            : 'forms.checkout.creditDetails')}
      </Typography>

      {loading ? (
        <Box>
          <SkeletonCheckoutStep3 />
          {defaultFooter}
        </Box>
      ) : (
        mainContent
      )}
    </Box>
  );
};

export default SmallWebsitePayment;
