import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesProductPrice } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesProductPrice,
    {
      isMobile: boolean;
      lang: string;
    }
  > =>
    createStyles({
      priceMainWrapper: ({ isMobile }) => ({
        display: isMobile ? 'block' : 'flex',
        alignItems: 'baseline',
        marginTop: '14px',
      }),
      priceWrapper: ({ isMobile }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: isMobile ? 'center' : 'initial',
        marginRight: isMobile ? 0 : '8px',
      }),
      pricePerUnit: ({ isMobile, lang }) => ({
        fontSize: isMobile ? '18px' : '20px',
        lineHeight: isMobile ? '16px' : '20px',
        color: theme.palette.text.primary,
        fontWeight: lang === 'ru' ? 700 : 600,
      }),
      sellingUnit: ({ isMobile }) => ({
        fontSize: isMobile ? '14px' : '18px',
        lineHeight: isMobile ? '16px' : '20px',
        color: theme.palette.text.primary,
        fontWeight: 500,
      }),
      pricePerGram: ({ isMobile }) => ({
        fontSize: '14px',
        lineHeight: '14px',
        color: '#7A7A7A',
        fontWeight: 'normal',
        marginTop: isMobile ? '4px' : '8px',
      }),
      promotionPriceWrapper: ({ isMobile }) => ({
        marginTop: isMobile ? theme.spacing(6 / 8) : 0,
      }),
      promotionShortName: ({ isMobile }) => ({
        fontSize: isMobile ? '12px' : '14px',
        fontWeight: 500,
        lineHeight: isMobile ? '14px' : '16px',
        color: theme.palette.error.main,
        letterSpacing: 'normal',
        display: '-webkit-box',
        '-webkitBoxOrient': 'vertical',
        '-webkitLineClamp': 1,
        overflow: 'hidden !important',
      }),
      originalPriceAndSellingUnit: {
        fontSize: '13px',
        lineHeight: '17px',
        color: '#7A7A7A',
        textDecoration: 'line-through',
      },
    }),
);
