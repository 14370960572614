import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesSelfPickupsDropDown } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesSelfPickupsDropDown,
    {
      isMobile: boolean;
      lang: string;
    }
  > =>
    createStyles({
      areaName: {
        fontSize: '16px',
        lineHeight: '20px',
        marginLeft: '8px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '85%',
        [theme.breakpoints.up('md')]: {
          marginLeft: '10px',
          width: '100%',
        },
      },
      dayAndTime: {
        fontSize: '16px',
        lineHeight: '20px',
      },
      storeIcon: {
        fontSize: '18px',
        [theme.breakpoints.up('md')]: {
          fontSize: '28px',
        },
      },
      verticalDivider: {
        borderLeft: '2px solid',
        height: '20px',
        margin: '0px 8px',
      },
      distanceWrapper: {
        margin: '4px 0px 0px 8px',
        [theme.breakpoints.up('md')]: {
          margin: '4px 0px 0px 10px',
        },
      },
      dayAndTimeWrapper: {
        margin: '4px 0px 0px 8px',
        [theme.breakpoints.up('md')]: {
          margin: 0,
        },
      },
      distance: {
        lineHeight: 1.15,
      },
      paper: {
        background: theme.palette.grey[50],
        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.15)',
        minWidth: 'auto !important',
        width: 'calc(100% - 30px) !important',
        maxWidth: '600px !important',
        [theme.breakpoints.up('md')]: {
          minWidth: '650px !important',
          width: '100% !important',
        },
      },
      menuList: {
        padding: '10px 0px',
        [theme.breakpoints.up('md')]: {
          padding: '11px 0px',
        },
      },
      menuItem: {
        padding: '10px 14px',
        '& div': {
          alignItems: 'initial !important',
        },
        '&:focus': {
          backgroundColor: `transparent !important`,
        },
        '&:hover': {
          backgroundColor: `rgba(0, 0, 0, 0.04) !important`,
        },
        [theme.breakpoints.up('md')]: {
          padding: '11px 22px 11px 22px',
        },
      },
      notchedOutline: {
        padding: '16px !important',
      },
      menuItemSelected: {
        backgroundColor: `transparent !important`,
        color: theme.palette.primary.main,
        '& div': {
          color: theme.palette.primary.main,
        },
        [theme.breakpoints.up('md')]: {
          '&:hover': {
            backgroundColor: `transparent !important`,
          },
        },
      },
      placeholder: {
        fontSize: '16px',
      },
    }),
);
