import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TWithMobileStyles } from 'types';

import { TStylesSmallWebsitePayment } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesSmallWebsitePayment,
    TWithMobileStyles & { couponErrorMessage?: string; paymentsMethodsArrayLength?: number }
  > =>
    createStyles({
      root: {
        padding: theme.spacing(22 / 8, 1.75), // 30px 0px
        [theme.breakpoints.up('md')]: {
          padding: 0,
          marginLeft: '100px',
        },
      },
      formTitle: {
        fontSize: theme.spacing(2.75), // 22px
        marginBottom: theme.spacing(1.5), // 12px
        [theme.breakpoints.up('md')]: {
          fontSize: theme.spacing(3.75), // 30px
          marginBottom: theme.spacing(14 / 8),
          lineHeight: '34px',
        },
      },
      dividerTriangle: {
        width: theme.spacing(1.5), // 12px
        height: theme.spacing(1.5), // 12px
        border: `1px solid ${theme.palette.grey.A100}`,
        backgroundColor: theme.palette.background.paper,
        bottom: theme.spacing(-38 / 8),
        left: theme.spacing(9.25), // 74px
        transform: 'rotate(45deg)',
        borderBottomColor: 'transparent',
        borderRightColor: 'transparent',
        position: 'absolute',
      },
      label: {
        margin: theme.spacing(30 / 8, 0, 14 / 8),
        [theme.breakpoints.up('md')]: {
          margin: theme.spacing(24 / 8, 0, 14 / 8),
        },
      },
      creditDetailsText: {
        margin: '24px 0px 14px',
      },
      addNewCardText: {
        margin: theme.spacing(30 / 8, 0, 14 / 8),
      },
      mobilePersonalInfo: ({ isMobile }) => ({
        marginBottom: isMobile ? theme.spacing(2.75) : theme.spacing(18 / 8),
      }),
      mobilePersonalText: {
        display: '-webkit-box',
        '-webkitBoxOrient': 'vertical',
        '-webkitLineClamp': 1,
        overflow: 'hidden !important',
        fontSize: theme.spacing(1.875), // 15px
        maxWidth: '262px',
      },
      mobileEditIcon: {
        fontSize: theme.spacing(2.25), // 18px
      },
      link: {
        textDecoration: 'none',
      },
      couponErrorMessage: {
        color: theme.palette.error.main,
        fontSize: theme.spacing(13 / 8),
      },
      couponWrapper: {
        [theme.breakpoints.up('md')]: {
          marginBottom: ({ couponErrorMessage }) =>
            theme.spacing((couponErrorMessage ? 15 : 18) / 8),
        },
      },
      title: {
        fontFamily: 'Heebo, "Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: '24px',
        [theme.breakpoints.up('md')]: {
          fontFamily: 'Rubik, "Roboto", "Helvetica", "Arial", sans-serif',
        },
      },
      titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      couponExternalNotes: {
        color: theme.palette.grey[600],
        fontSize: '14px',
        lineHeight: 1.29,
        [theme.breakpoints.up('md')]: {
          fontSize: '15px',
          lineHeight: 1.4,
        },
      },
      availablePaymentMethodsWrapper: {
        marginBottom: theme.spacing(24 / 8),
      },
      methodsWrapper: {
        marginTop: theme.spacing(1.75), // 14px
        marginBottom: theme.spacing(3.875), // 31px
      },
      dividerRoot: {
        background: theme.palette.grey.A200,
      },
      paymentMethodItem: {
        position: 'relative',
        background: theme.palette.background.paper,
        borderRadius: '6px',
        [theme.breakpoints.up('md')]: {
          maxWidth: '159px',
        },
      },
      paymentGridWrapper: {
        width: 'calc(100% + 14px)',
        margin: theme.spacing(-0.875), // -7px
        flexWrap: 'wrap',
        [theme.breakpoints.up('md')]: {
          width: 'auto !important',
        },
      },
      paymentGridItemWrapper: {
        padding: theme.spacing(0.875), // 7px
        [theme.breakpoints.up('md')]: {
          maxWidth: 'fit-content !important',
        },
      },
      tooltip: {
        padding: theme.spacing(1),
        backgroundColor: `${theme.palette.secondary.main}!important`,
      },
      saveCreditLabel: {
        fontSize: '14px',
        color: theme.palette.grey['600'],
        letterSpacing: '-0.2px',
        lineHeight: '18px',
      },
    }),
);
