import { Box } from '@material-ui/core';
import classNames from 'classnames';
import Typography from 'ui/common/Typography';
import { useMobile } from 'hooks';
import useProductPrice from 'hooks/useProductPrice';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getLang } from 'store/modules/config/selectors';
import { IProductPrice } from './types';

import useStyles from './styles';

const ProductPrice: FC<IProductPrice> = ({ storeProductDisplay }) => {
  const { isMobile } = useMobile();

  const { pricePerUnit, pricePerOneHundredGram } = useProductPrice();

  const lang = useSelector(getLang);

  const classes = useStyles({
    isMobile,
    lang,
  });

  return (
    <Box className={classes.priceMainWrapper}>
      <Box className={classes.priceWrapper}>
        {pricePerUnit(
          storeProductDisplay.price as number,
          storeProductDisplay.pricingSellingUnitName,
          classNames(classes.pricePerUnit, 'font-family-heebo'),
          classNames(classes.sellingUnit, 'font-family-heebo'),
        )}
      </Box>
      {storeProductDisplay.promotion && storeProductDisplay.promotion.shortName && (
        <Box className={classNames(classes.priceWrapper, classes.promotionPriceWrapper)}>
          <Typography
            className={classNames(classes.promotionShortName, 'font-family-heebo')}
            isTranslate={false}
          >
            {storeProductDisplay.promotion.shortName}
          </Typography>
        </Box>
      )}
      {storeProductDisplay.promoted && storeProductDisplay.originalPrice && (
        <Box className={classNames(classes.priceWrapper, classes.promotionPriceWrapper)}>
          {pricePerUnit(
            storeProductDisplay.originalPrice as number,
            storeProductDisplay.pricingSellingUnitName,
            classNames(classes.originalPriceAndSellingUnit, 'font-family-heebo'),
            classNames(classes.originalPriceAndSellingUnit, 'font-family-heebo'),
          )}
        </Box>
      )}
      {!storeProductDisplay.soldByWeight &&
        !storeProductDisplay.bagOfProducts &&
        storeProductDisplay.productSellingUnits[0].estimatedUnitWeight && (
          <Box className={classes.priceWrapper}>
            {pricePerOneHundredGram(
              storeProductDisplay.productSellingUnits[0].estimatedUnitWeight,
              storeProductDisplay.price,
              storeProductDisplay.product.liquid,
              classNames(classes.pricePerGram, 'font-family-heebo'),
            )}
          </Box>
        )}
    </Box>
  );
};

export default ProductPrice;
