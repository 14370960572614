import { createStyles, makeStyles, StyleRules } from '@material-ui/core';
import { TSmallWebsiteContent } from './types';

export default makeStyles(
  (theme): StyleRules<TSmallWebsiteContent> =>
    createStyles({
      websiteContentWrapper: {
        padding: '30px 14px 50px',
        maxWidth: '628px',
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          padding: '98px 0px 92px',
          display: 'flex',
          maxWidth: '1200px',
          justifyContent: 'space-between',
        },
      },
      mainContent: {
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          justifyContent: 'space-between',
        },
      },
      pageTitle: {
        fontFamily: 'Heebo',
        fontSize: '22px',
        lineHeight: '24px',
        color: theme.palette.secondary.main,
        marginBottom: '7px',
        [theme.breakpoints.up('md')]: {
          fontSize: '30px',
          lineHeight: '34px',
          marginBottom: '14px',
        },
      },
      continueToCheckout: {
        width: '100%',
        marginTop: '48px',
      },
      iconArrowRight: {
        color: theme.palette.secondary.main,
        width: '9px',
        height: '9px',
        fontSize: '9px',
        marginRight: '3px',
      },
      backBtn: {
        fontWeight: 'unset',
        lineHeight: 'unset',
        background: 'none',
        padding: '12px 0px 0px 14px !important',
      },
    }),
);
