import { Box, Button } from '@material-ui/core';
import { StoreService, TPrepareToPlaceOrderBody } from 'api';
import classNames from 'classnames';
import BackIcon from 'components/common/icons/BackIcon';
import SmallWebsiteCheckout from 'components/common/SmallWebsiteContent/components/checkout/SmallWebsiteCheckout';
import SmallWebsiteProductCard from 'components/common/SmallWebsiteContent/components/SmallWebsiteProductCard';
import SelfPickupsDropDown, {
  ISelfPickupDropDownOption,
} from 'components/common/SmallWebsiteContent/components/SelfPickupsDropDown';
import ContinueToPaymentFixed from 'components/mobile/ContinueToPaymentFixed';
import {
  TAvailableArea,
  TSelfPickupAreaItem,
  useAvailableServiceAreas,
  useCart,
  useDialog,
  useMobile,
  useProduct,
  useSelfPickupAndDeliveryService,
} from 'hooks';
import useRenderDate from 'hooks/useRenderDate/useRenderDate';
import i18next from 'i18next';
import { find } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  authSelectors,
  dialogActions,
  GENERIC_DIALOG,
  initialOrderDetailsState,
  LOGIN_DIALOG,
  notifyActions,
  prepareToPlaceOrderActions,
  prepareToPlaceOrderSelectors,
  storeProductSelectors,
  TOrderDetailsReducerState,
} from 'store';
import { updateOrderDetails } from 'store/modules/orderDetails/actions';
import { getOrderDetails } from 'store/modules/orderDetails/selectors';
import { TServiceAreaHoursServer } from 'types';
import CTAButton from 'ui/common/buttons/CTAButton';
import Typography from 'ui/common/Typography';
import { shouldOrderHandlingFeeBeCharged } from 'utils/helpers/cartEstimation';
import useStyles from './styles';

const SmallWebsiteContent: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const orderDetails = useSelector(getOrderDetails);
  const prepareToPlaceOrder = useSelector(prepareToPlaceOrderSelectors.getPrepareToPlaceOrder);
  const { storeProductIds } = useSelector(storeProductSelectors.storeProductsData);

  const {
    getUserLocationAndUpdateDistanceToSelfPickupPoints,
    sortSelfPickupAreasByPointName,
    showChangeOrderTypeNotPossibleInEditModeDialog,
  } = useSelfPickupAndDeliveryService();
  const { getDisplayableStoreProduct } = useProduct();
  const { showDialog } = useDialog();
  const { renderDay } = useRenderDate();
  const { isEmpty, itemsEstimation } = useCart();
  const { getAvailableAreas, selfPickupAreasArray } = useAvailableServiceAreas();

  const [isPrepareToPlaceOrderLoading, setIsPrepareToPlaceOrderLoading] = useState(false);
  const [selfPickupAreasToDisplay, setSelfPickupAreasToDisplay] =
    useState<TSelfPickupAreaItem[]>(selfPickupAreasArray);
  const [selectedPickupArea, setSelectedPickupArea] = useState<TOrderDetailsReducerState>();

  const [isPaymentSectionActive, setIsPaymentSectionActive] = useState<boolean>(false);
  const [isContinueToPaymentClick, setIsContinueToPaymentClick] = useState<boolean>(false);

  const classes = useStyles();

  const catalog = useMemo(() => {
    return storeProductIds.map((productId) => {
      const storeProductToDisplay = getDisplayableStoreProduct(productId);

      if (!storeProductToDisplay) return;
      return (
        <Box mt={isMobile ? 14 / 8 : 24 / 8} key={productId}>
          <SmallWebsiteProductCard
            storeProductDisplay={storeProductToDisplay}
            onProductChange={() => {
              setIsPaymentSectionActive(false);
              setIsContinueToPaymentClick(false);
            }}
          />
        </Box>
      );
    });
  }, [getDisplayableStoreProduct, isMobile, storeProductIds]);

  const prepareWeekday = useCallback((parsedAreaHours: TServiceAreaHoursServer[]) => {
    const availableHours = new Map();

    parsedAreaHours.forEach((dayAndTime) => {
      if (availableHours.get(dayAndTime.weekday)) {
        availableHours.set(dayAndTime.weekday, [
          ...availableHours.get(dayAndTime.weekday),
          dayAndTime,
        ]);
      } else {
        availableHours.set(dayAndTime.weekday, [dayAndTime]);
      }
    });

    return Array.from(availableHours, ([key, value]) => {
      return {
        weekday: key,
        openHours: value,
      };
    });
  }, []);

  const getClientLocationAndSortSelfPickupPoints = useCallback(
    (selfPickupAreas) => {
      getUserLocationAndUpdateDistanceToSelfPickupPoints(
        selfPickupAreas,
        (selfPickupAreasWithDistanceToClient) => {
          setSelfPickupAreasToDisplay(
            selfPickupAreasWithDistanceToClient.sort(
              (a, b) =>
                ((a.serviceArea.distanceInKmToSelectedAddress as number) || 0) -
                ((b.serviceArea.distanceInKmToSelectedAddress as number) || 0),
            ),
          );
        },
        () => setSelfPickupAreasToDisplay(sortSelfPickupAreasByPointName(selfPickupAreas)),
      );
    },
    [getUserLocationAndUpdateDistanceToSelfPickupPoints, sortSelfPickupAreasByPointName],
  );

  const mobileCheckout = useMemo(() => {
    return (
      <>
        <Button
          className={classNames(classes.backBtn, 'unstyled-button')}
          onClick={() => {
            setIsPaymentSectionActive(false);
            setIsContinueToPaymentClick(false);
          }}
        >
          <Box display="flex" alignItems="center">
            <BackIcon classes={{ root: classes.iconArrowRight }} />
            <Typography fontSize={14} color="secondary">
              {'link.backToTheShop'}
            </Typography>
          </Box>
        </Button>
        <SmallWebsiteCheckout isPaymentSectionActive />
      </>
    );
  }, [classes.backBtn, classes.iconArrowRight]);

  const showHandlingFeeWillBeChargedDialog = useCallback(() => {
    dispatch(
      dialogActions.showDialog({
        dialogType: GENERIC_DIALOG,
        contentProps: {
          title: 'dialog.handlingFee.title',
          body: i18next.t('dialog.handlingFee.body', {
            orderHandlingFeeThreshold: prepareToPlaceOrder.orderHandlingFeeThreshold,
            orderHandlingFee: prepareToPlaceOrder.orderHandlingFee,
          }),
          buttons: [
            {
              text: 'dialog.handlingFee.okButton',
              variant: 'contained',
              closeButton: true,
              onClick: () => setIsPaymentSectionActive(true),
            },
            {
              text: 'dialog.handlingFee.cancelButton',
              variant: 'outlined',
              closeButton: true,
              onClick: () => {
                setIsPaymentSectionActive(false);
                setIsContinueToPaymentClick(false);
              },
            },
          ],
        },
      }),
    );
  }, [
    dispatch,
    prepareToPlaceOrder.orderHandlingFee,
    prepareToPlaceOrder.orderHandlingFeeThreshold,
  ]);

  const showMinOrderValueNotPassedDialog = useCallback(() => {
    const body =
      prepareToPlaceOrder.serviceAreaUsageFees === 0
        ? t('dialog.orderMinimum.bodyIfNoDeliveryFee', {
            orderMinimum: prepareToPlaceOrder.orderMinTotalValue,
          })
        : t(`dialog.orderMinimum.body.${orderDetails.orderType}`, {
            orderMinimum: prepareToPlaceOrder.orderMinTotalValue,
          });

    dispatch(
      dialogActions.showDialog({
        dialogType: GENERIC_DIALOG,
        contentProps: {
          title: 'dialog.orderMinimum.title',
          body,
          buttons: [
            {
              text: 'button.ok',
              variant: 'contained',
              closeButton: true,
            },
          ],
        },
      }),
    );
  }, [dispatch, orderDetails.orderType, prepareToPlaceOrder, t]);

  const checkOrderMinTotalAndHandlingFee = useCallback(() => {
    setIsContinueToPaymentClick(true);

    if (!(itemsEstimation >= prepareToPlaceOrder.orderMinTotalValue)) {
      setIsPaymentSectionActive(false);
      setIsContinueToPaymentClick(false);
      showMinOrderValueNotPassedDialog();
      return;
    }

    if (
      shouldOrderHandlingFeeBeCharged(
        itemsEstimation,
        prepareToPlaceOrder.orderMinTotalValue,
        prepareToPlaceOrder.orderHandlingFeeThreshold,
      )
    ) {
      setIsPaymentSectionActive(false);
      setIsContinueToPaymentClick(false);
      showHandlingFeeWillBeChargedDialog();
      return;
    }
    if (isMobile) {
      window.scrollTo(0, 0); // to prevent staying on mobile in the bottom of the page when go to payment
    }
    setIsPaymentSectionActive(true);
  }, [
    itemsEstimation,
    prepareToPlaceOrder.orderMinTotalValue,
    prepareToPlaceOrder.orderHandlingFeeThreshold,
    isMobile,
    showMinOrderValueNotPassedDialog,
    showHandlingFeeWillBeChargedDialog,
  ]);

  const onContinueToPayment = useCallback(() => {
    if (!isLoggedIn) {
      showDialog({
        dialogType: LOGIN_DIALOG,
        contentProps: {
          onLoginSuccess: () => setIsContinueToPaymentClick(true),
        },
      });
      return;
    }

    checkOrderMinTotalAndHandlingFee();
  }, [isLoggedIn, checkOrderMinTotalAndHandlingFee, showDialog]);

  const onSelfPickupLocationClicked = useCallback(
    (serviceArea?: ISelfPickupDropDownOption['customValue']) => {
      setSelectedPickupArea(serviceArea);
      setIsContinueToPaymentClick(false);
      setIsPaymentSectionActive(false);

      if (serviceArea) {
        if (isLoggedIn && serviceArea?.selfPickupLocation?.storeServiceAreaId) {
          const body: TPrepareToPlaceOrderBody = {
            orderType: 'selfPickup',
            storeServiceAreaId: serviceArea?.selfPickupLocation.storeServiceAreaId,
            timeFrame: serviceArea.timeFrame,
          };
          dispatch(
            prepareToPlaceOrderActions.prepareToPlaceOrderRequestAction(
              body,
              {
                selfPickupLocation: serviceArea.selfPickupLocation,
                duration: serviceArea.timeFrame.duration,
              },
              { setLoading: setIsPrepareToPlaceOrderLoading },
            ),
          );
          return;
        }
        dispatch(
          updateOrderDetails({
            ...serviceArea,
          }),
        );
        return;
      }
      dispatch(
        updateOrderDetails({
          ...initialOrderDetailsState,
        }),
      );
    },
    [dispatch, isLoggedIn],
  );
  useEffect(() => {
    setSelectedPickupArea(orderDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      // once user login we re-fetch areas and do prepare to place order
      setIsPrepareToPlaceOrderLoading(true);
      getAvailableAreas();
      return;
    }

    StoreService.getStoreServiceAreas().then((actionResult) => {
      const customAreas: TSelfPickupAreaItem[] = [];

      actionResult.data.selfPickupAreas.forEach((area) => {
        const parsedAreaHours: TServiceAreaHoursServer[] = JSON.parse(
          area.serviceAreaHoursJson,
        ).sort((a: TServiceAreaHoursServer, b: TServiceAreaHoursServer) => a.openHour - b.openHour);

        const preparedWeekdayAndOpenHours = prepareWeekday(parsedAreaHours).sort(
          (a, b) => a.weekday - b.weekday,
        );

        const availableArea: TAvailableArea[] = preparedWeekdayAndOpenHours.map(
          (selfPickupAreaDay, index) => {
            return {
              availableHours: selfPickupAreaDay.openHours,
              dayOfWeek: {
                id: selfPickupAreaDay.weekday,
                resolvedName: t(renderDay(selfPickupAreaDay.weekday, true)),
                name: t(renderDay(selfPickupAreaDay.weekday, true), { lng: 'en' }), // to make it look like from the server
              },
              id: index,
              date: '',
            };
          },
        );

        customAreas.push({
          serviceArea: {
            coordinatesLatLng: area.coordinatesLatLng,
            name: area.name,
            storeServiceAreaId: area.id,
          },
          availableArea,
        });
      });

      getClientLocationAndSortSelfPickupPoints(customAreas);
    });
  }, [
    getAvailableAreas,
    getClientLocationAndSortSelfPickupPoints,
    isLoggedIn,
    prepareWeekday,
    renderDay,
    t,
  ]);

  useEffect(() => {
    if (isLoggedIn && !isPrepareToPlaceOrderLoading && isContinueToPaymentClick) {
      // when user login after click on "continue to payment"
      if (prepareToPlaceOrder.orderMinTotalValue || prepareToPlaceOrder.orderHandlingFeeThreshold) {
        checkOrderMinTotalAndHandlingFee();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContinueToPaymentClick, isLoggedIn, isPrepareToPlaceOrderLoading]);

  useEffect(() => {
    if (!selfPickupAreasArray.length) return;

    // when we received validated areas after login we check if what user chose is still relevant
    getClientLocationAndSortSelfPickupPoints(selfPickupAreasArray);

    if (orderDetails.selfPickupLocation?.storeServiceAreaId && orderDetails.orderMode === 'new') {
      const selectedArea = find(selfPickupAreasArray, [
        'serviceArea.storeServiceAreaId',
        orderDetails.selfPickupLocation?.storeServiceAreaId,
      ]);

      const selectedDay = find(selectedArea?.availableArea, [
        'dayOfWeek.id',
        orderDetails.preferredDay,
      ]);

      const selectedTime = find(selectedDay?.availableHours, [
        'openHour',
        orderDetails.preferredHour,
      ]);

      if (selectedArea && selectedDay && selectedTime) {
        const body: TPrepareToPlaceOrderBody = {
          orderType: 'selfPickup',
          storeServiceAreaId: orderDetails.selfPickupLocation.storeServiceAreaId,
          timeFrame: {
            openHour: selectedTime.openHour,
            date: selectedDay?.date,
            dayOfWeek: selectedDay.dayOfWeek,
          },
        };

        dispatch(
          prepareToPlaceOrderActions.prepareToPlaceOrderRequestAction(
            body,
            {
              selfPickupLocation: orderDetails.selfPickupLocation,
              duration: selectedTime.duration,
            },
            { setLoading: setIsPrepareToPlaceOrderLoading },
          ),
        );

        setSelectedPickupArea({
          ...orderDetails,
          orderDate: selectedDay?.date,
        });
        return;
      }

      if (orderDetails.selfPickupLocation.storeServiceAreaId) {
        // to do it only if some self pickup was selected before
        dispatch(
          notifyActions.showNotification({
            message: 'smallEC.pickupPointNotAvailable',
          }),
        );
        dispatch(
          updateOrderDetails({
            ...initialOrderDetailsState,
          }),
        );
      }

      setIsPaymentSectionActive(false);
      setIsContinueToPaymentClick(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getClientLocationAndSortSelfPickupPoints, selfPickupAreasArray]);

  useEffect(() => {
    if (!orderDetails?.selfPickupLocation?.storeServiceAreaId) {
      setSelectedPickupArea(undefined); // to clean if something was selected before

      setIsContinueToPaymentClick(false);
      setIsPaymentSectionActive(false);
    }
  }, [orderDetails]);

  return (
    <>
      {isMobile && isPaymentSectionActive && mobileCheckout}
      {(!isMobile || !isPaymentSectionActive) && (
        <Box className={classes.websiteContentWrapper}>
          <Box className={classes.mainContent}>
            <Box>
              <Typography
                className={classes.pageTitle}
                fontWeight="bold"
                style={{ opacity: orderDetails?.orderMode === 'new' ? 1 : 0.3 }}
              >
                {'dialog.location.chooseSelfPickup'}
              </Typography>
              {selfPickupAreasToDisplay && (
                <SelfPickupsDropDown
                  selectedPickupArea={selectedPickupArea}
                  selfPickupAreas={selfPickupAreasToDisplay}
                  handleSelfPickupClick={onSelfPickupLocationClicked}
                  disabled={orderDetails?.orderMode !== 'new'}
                  handleDropDownClick={
                    orderDetails?.orderMode !== 'new'
                      ? showChangeOrderTypeNotPossibleInEditModeDialog
                      : () => {}
                  }
                />
              )}
              <Box mt={isMobile ? 30 / 8 : 45 / 8}>
                <Typography className={classes.pageTitle} fontWeight="bold">
                  {'smallEC.addProducts'}
                </Typography>
                {catalog}
              </Box>

              {!isMobile && !isPaymentSectionActive && (
                <CTAButton
                  className={classes.continueToCheckout}
                  disabled={isEmpty || !orderDetails.selfPickupLocation?.storeServiceAreaId}
                  onClick={onContinueToPayment}
                >
                  {'button.continueToCheckout'}
                </CTAButton>
              )}
            </Box>
          </Box>
          {!isMobile && (
            <Box
              style={{
                opacity: isPaymentSectionActive ? 1 : 0.3,
                pointerEvents: isPaymentSectionActive ? 'all' : 'none',
              }}
            >
              <SmallWebsiteCheckout isPaymentSectionActive={!!isPaymentSectionActive} />
            </Box>
          )}
        </Box>
      )}
      {isMobile &&
        !!orderDetails.selfPickupLocation?.storeServiceAreaId &&
        !isEmpty &&
        !isPaymentSectionActive && (
          <Box component="section" position="sticky" bottom={0} zIndex={3}>
            <ContinueToPaymentFixed
              hideBasketIcon
              pinToBody={false}
              onContinueToPayment={onContinueToPayment}
              label={'button.goToCheckout'}
            />
          </Box>
        )}
    </>
  );
};
export default SmallWebsiteContent;
