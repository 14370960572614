import { Box } from '@material-ui/core';
import { useMobile, useProduct } from 'hooks';
import useRelevancy from 'hooks/useRelevancy';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getLang } from 'store/modules/config/selectors';
import { TSupplier } from 'types';
import Typography from 'ui/common/Typography';

import useStyles from './styles';
import { IProductDetails } from './types';

const ProductDetails: FC<IProductDetails> = ({ storeProductDisplay }) => {
  const { isMobile } = useMobile();

  const { t } = useTranslation();

  const { renderRelevancy, relevancyDefaultText } = useRelevancy(
    storeProductDisplay.currentRelevancy?.name,
  );

  const { getProductOrBagDetails, shouldDisplaySupplier } = useProduct();

  const lang = useSelector(getLang);

  const classes = useStyles({
    isMobile,
    lang,
  });

  const productExtraDetails = useMemo(() => {
    if (
      !storeProductDisplay.bagOfProducts &&
      (storeProductDisplay.productExtraDetails ||
        shouldDisplaySupplier(storeProductDisplay) ||
        storeProductDisplay.currentRelevancy)
    ) {
      return (
        <Box>
          <Typography className={classes.productExtraDetails} isTranslate={false}>
            {!!storeProductDisplay.productExtraDetails &&
              `${storeProductDisplay.productExtraDetails}. `}
            {shouldDisplaySupplier(storeProductDisplay) &&
              `${t('storeProductCard.supplier')}: ${
                (storeProductDisplay.supplier as TSupplier).name
              }`}
          </Typography>
        </Box>
      );
    }
  }, [classes.productExtraDetails, shouldDisplaySupplier, storeProductDisplay, t]);

  const bagExtraDetailsText = useMemo(() => {
    return getProductOrBagDetails(storeProductDisplay.id, true);
  }, [getProductOrBagDetails, storeProductDisplay.id]);

  const bagItems = useMemo(() => {
    return (
      <Typography
        className={classes.productExtraDetails}
        dangerouslySetInnerHTML={{ __html: bagExtraDetailsText as string }}
      />
    );
  }, [classes.productExtraDetails, bagExtraDetailsText]);

  return (
    <>
      {storeProductDisplay.bagOfProducts && bagItems}
      {productExtraDetails}
      {storeProductDisplay.currentRelevancy && (
        <Box className={classes.ratingWrapper}>
          {renderRelevancy}
          <Typography className={classes.descriptionText} isTranslate={false}>
            {storeProductDisplay.currentRelevancyFreeText || relevancyDefaultText}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ProductDetails;
