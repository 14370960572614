/* eslint-disable @typescript-eslint/no-explicit-any */
import { CatalogService } from 'api';
import { ContentPageService } from 'api/services/ContentPageService';
import { IIndexPage } from 'components/common/HomePageContent';

import MainPagesWrapper from 'components/common/MainPagesWrapper';
import SmallWebsiteMainLayout from 'components/common/SmallWebsiteContent/components/SmallWebsiteMainLayout';
import SmallWebsitePageWrapper from 'components/common/SmallWebsiteContent/components/SmallWebsitePageWrapper/SmallWebsitePageWrapper';
import { useWebsiteDetails } from 'hooks';
import useHomePageContent from 'hooks/useHomePageContent';

import { NextPage, NextPageContext } from 'next';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'redux';
import { authSelectors, TStoreState } from 'store';
import { setCatalogId } from 'store/modules/catalog/actions';
import { getStoreProductsRequest } from 'store/modules/storeProduct/actions';

import MainLayout from 'ui/common/layout/MainLayout';
import { isServer } from 'utils/helpers/isServer';

const Index: NextPage<IIndexPage> = (pageProps) => {
  const welcomeDialogContent = (pageProps as any)?.initialReduxState?.store?.websiteDetails
    ?.promotionalText;
  const isFirstRender = !!(pageProps as any)?.initialReduxState;
  const onClientBehalf = useSelector(authSelectors.onClientBehalf);
  const websiteDetails = useWebsiteDetails();
  const { homePageId, pageContent, populatePageContent } = useHomePageContent();

  useEffect(() => {
    if (
      !pageProps.props?.homePageContent &&
      homePageId &&
      !websiteDetails.websiteSettings.isSmallEC
    ) {
      populatePageContent(homePageId);
    }
  }, [
    populatePageContent,
    homePageId,
    pageProps.props?.homePageContent,
    pageProps,
    websiteDetails.websiteSettings.isSmallEC,
  ]);

  const mainPageLayout = useMemo(() => {
    if (websiteDetails.websiteSettings.isSmallEC) {
      return (
        <SmallWebsiteMainLayout showBigHeader>
          <SmallWebsitePageWrapper
            welcomeDialogContent={welcomeDialogContent}
            isFirstRender={isFirstRender}
          />
        </SmallWebsiteMainLayout>
      );
    }

    return (
      <MainLayout
        showSearchSection
        showAccessibilityButtons
        showBigHeader={!homePageId && !onClientBehalf}
      >
        <MainPagesWrapper
          welcomeDialogContent={welcomeDialogContent}
          isFirstRender={isFirstRender}
          isShowHomePage={!!homePageId}
          homePageContent={pageProps.props?.homePageContent || pageContent}
        />
      </MainLayout>
    );
  }, [
    homePageId,
    isFirstRender,
    onClientBehalf,
    pageContent,
    pageProps.props?.homePageContent,
    websiteDetails.websiteSettings.isSmallEC,
    welcomeDialogContent,
  ]);

  return mainPageLayout;
};

Index.getInitialProps = async ({ req, store }: NextPageContext & { store: Store }) => {
  const state: TStoreState = store.getState();
  let homePageContent;

  if (isServer()) {
    const xForwardedIpAddress = req?.headers['x-forwarded-for'] as string;

    const limitedCatalogResult = await CatalogService.getPublicCatalog({
      websiteJWE: state.store.websiteDetails.jsonWebEncryption,
      limited: true,
      xForwardedIpAddress,
    });

    store.dispatch(
      getStoreProductsRequest(
        limitedCatalogResult.data.products,
        state.store.websiteDetails.store.performSellingUnitsEstimationLearning,
      ),
    );
    store.dispatch(setCatalogId(state.store.websiteDetails.subCatalog?.id));

    const homePageId = state.store.websiteDetails.contentPages.find(
      (contentPage) => contentPage.type === 'home' && contentPage.published,
    )?.id as string;

    if (homePageId && !state.store.websiteDetails.websiteSettings.isSmallEC) {
      const actionResult = await ContentPageService.getStorePageContent(
        homePageId,
        xForwardedIpAddress,
      );

      if (actionResult.data) {
        homePageContent = JSON.parse(actionResult.data.content).compositeComponents;
      }
    }
  }

  return {
    props: {
      homePageContent,
    },
  };
};

export default Index;
