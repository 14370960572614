import { Box } from '@material-ui/core';

import useStyles from 'components/common/SmallWebsiteContent/components/SmallWebsitePageWrapper/styles';
import { ISmallWebsitePageWrapper } from 'components/common/SmallWebsiteContent/components/SmallWebsitePageWrapper/types';
import SmallWebsiteContent from 'components/common/SmallWebsiteContent/index';
import PWABannerPrompt from 'components/mobile/PWABannerPrompt';

import { useDialog, useMobile } from 'hooks';
import usePWAInstallerPrompt from 'hooks/usePWAInstallerPrompt';
import { useRouter } from 'next/router';
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN_DIALOG, websiteActions } from 'store';
import { authSelectors } from 'store/modules/auth';
import { checkAndFetchBranchCatalogIfNecessary } from 'store/modules/catalog/actions';
import { WELCOME_DIALOG } from 'store/modules/dialog';
import { introductoryPopupsCompletedAction } from 'store/modules/shoppingFlow/actions';
import { getWebsiteDetails } from 'store/modules/website/selectors';

const SmallWebsitePageWrapper: FC<ISmallWebsitePageWrapper> = ({
  welcomeDialogContent,
  isFirstRender = false,
}) => {
  const { isMobile } = useMobile();

  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const websiteDetails = useSelector(getWebsiteDetails);

  const router = useRouter();

  const { handleOnInstall, handleOnDismiss } = usePWAInstallerPrompt();

  const dispatch = useDispatch();

  const { showDialog } = useDialog();

  const classes = useStyles({
    isMobile,
  });

  const showWelcomeDialog = useCallback(
    (onWelcomeDialogContentAbsentCallback?: () => void) => {
      if (welcomeDialogContent && welcomeDialogContent.length && websiteDetails.showWelcomeDialog) {
        showDialog({
          dialogType: WELCOME_DIALOG,
        });

        dispatch(
          websiteActions.getWebsiteSuccess({
            websiteDetails: {
              ...websiteDetails,
              lastTimeShowingWelcomeDialog: new Date().getTime(),
            },
          }),
        );
        return;
      }

      if (onWelcomeDialogContentAbsentCallback) {
        onWelcomeDialogContentAbsentCallback();
      }
    },
    [dispatch, showDialog, websiteDetails, welcomeDialogContent],
  );

  useEffect(() => {
    if (isFirstRender) {
      if (!isLoggedIn) {
        showWelcomeDialog(() => dispatch(checkAndFetchBranchCatalogIfNecessary()));
        return;
      }

      showWelcomeDialog(() => dispatch(introductoryPopupsCompletedAction(true)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFirstRender && !isLoggedIn && router.query && router.query.showLoginDialog) {
      showDialog({
        dialogType: LOGIN_DIALOG,
      });
    }
  }, [isFirstRender, isLoggedIn, router.query, showDialog]);

  return (
    <Box zIndex={4}>
      <Box id="catalog" component="section" className={classes.childrenWrp}>
        <SmallWebsiteContent />
      </Box>
      {isMobile && websiteDetails.websiteSettings.pwaEnabled && (
        <PWABannerPrompt onInstall={handleOnInstall} onClose={handleOnDismiss} />
      )}
    </Box>
  );
};

export default SmallWebsitePageWrapper;
