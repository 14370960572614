import { Box } from '@material-ui/core';
import { useCart, useRenderPrice } from 'hooks';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { orderSelectors, prepareToPlaceOrderSelectors, TOrderDiscount } from 'store';
import { getOrderDiscounts } from 'store/modules/orderDiscounts/selectors';
import TooltipOnIcon from 'ui/common/TooltipOnIcon';
import Typography from 'ui/common/Typography';
import { getCartEstimation } from 'utils/helpers/cartEstimation';
import useStyles from './styles';

const SmallWebsiteFinalCostData: FC = () => {
  const renderPrice = useRenderPrice();
  const { cartEstimation } = useCart();

  const prepareToPlaceOrder = useSelector(prepareToPlaceOrderSelectors.getPrepareToPlaceOrder);
  const orderDiscounts = useSelector(getOrderDiscounts);
  const order = useSelector(orderSelectors.orderData);

  const [creditAtAccount, setCreditAtAccount] = useState<TOrderDiscount[]>();

  const classes = useStyles();

  const priceTemplate = useCallback(
    (label: string, price: number, bold?: boolean) => {
      return (
        <Box mb={1.25} display="flex" justifyContent="space-between">
          <Typography
            fontSize={16}
            fontWeight={bold ? 'medium' : 'regular'}
            color={bold ? 'textSecondary' : 'grey'}
          >
            {label}
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={bold ? 'bold' : 'regular'}
            variant="body2"
            color={price < 0 ? 'error' : 'textPrimary'}
          >
            {renderPrice(price)}
          </Typography>
        </Box>
      );
    },
    [renderPrice],
  );

  const prepareDiscounts = useMemo(() => {
    const promotionsAndDiscounts: TOrderDiscount[] = [];
    const userCreditAtAccount: TOrderDiscount[] = [];

    orderDiscounts.forEach((discount) => {
      if (discount.creditedAtCheckout && discount.discountSourceType.name === 'clientCoupon') {
        userCreditAtAccount.push(discount);
        return;
      }
      if (discount.discountType.name !== 'itemsPromotionDiscount') {
        promotionsAndDiscounts.push(discount);
      }
    });

    setCreditAtAccount(userCreditAtAccount);

    return (
      promotionsAndDiscounts.length &&
      +promotionsAndDiscounts.reduce((total, discount) => total + discount.totalPrice, 0).toFixed(2)
    );
  }, [orderDiscounts]);

  const clientCouponCreditedAtCheckout = useMemo(() => {
    if (!creditAtAccount?.length) return;

    return (
      <Box mb={1.25} display="flex" justifyContent="space-between">
        <Box display="flex">
          <Typography className={classes.creditAtCheckoutDescription}>
            {'discount.payment'}
          </Typography>
          <TooltipOnIcon
            tooltipTitle="discount.creditAtCheckoutDescription"
            tooltipPlacement="top"
            initialClasses={{ tooltip: classes.tooltip }}
          />
        </Box>

        <Typography fontSize={16} fontWeight="regular" variant="body2" color="error">
          {renderPrice(
            +creditAtAccount
              .reduce((total, discount) => total + -Math.abs(parseFloat(discount.valueFormula)), 0)
              .toFixed(2),
          )}
        </Typography>
      </Box>
    );
  }, [classes.creditAtCheckoutDescription, classes.tooltip, creditAtAccount, renderPrice]);

  return (
    <Box>
      {priceTemplate('topCard.totalShoppingCart', getCartEstimation(order, [], 0))}
      {!!prepareToPlaceOrder.serviceAreaUsageFees &&
        priceTemplate('serviceAreaUsageFees.selfPickup', prepareToPlaceOrder.serviceAreaUsageFees)}
      {!!prepareDiscounts && priceTemplate('smallEC.promotionsAndDiscounts', prepareDiscounts)}
      {clientCouponCreditedAtCheckout}
      {priceTemplate('order.totalToPay', cartEstimation, true)}
    </Box>
  );
};

export default SmallWebsiteFinalCostData;
